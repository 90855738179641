<script setup lang="ts">
import { LandingPageFieldsFragment, MediaImageFragment, ParagraphHeroItem } from '~/api/graphql/generated'
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/vue-splide'
import { Link } from '~/types'
import { type UseSwipeDirection, useSwipe } from '@vueuse/core'

interface Slide {
  image: MediaImageFragment
  title: string
  label: string
  link: Link
}
const props = defineProps<{
  entity: LandingPageFieldsFragment
}>()

const slides = computed(() => {
  return (
    props.entity.fieldHero?.map((slide) => {
      const item = slide?.entity as ParagraphHeroItem
      return {
        image: {
          small: item?.fieldMobileImage?.entity as MediaImageFragment,
          large: item?.fieldImage?.entity as MediaImageFragment,
        },
        label: item.fieldTagline || 'label',
        title: item.fieldTitle || '',
        link: {
          url: item.fieldLink?.url?.path || '',
          text: item.fieldLink?.title || '',
          target: item.fieldLink?.options.attributes.target || '_self',
        },
      }
    }) || []
  )
})

const splide = ref<typeof Splide | null>(null)
const options = {
  type: 'fade',
  autoplay: true,
  interval: 4000,
  rewind: true,
  arrows: false,
  pauseOnHover: false,
  mediaQuery: 'min',
  breakpoints: {
    1024: {
      pagination: false,
      drag: false,
    },
  },
} as Options

const swipeTarget = ref(null)
useSwipe(swipeTarget, {
  onSwipeEnd: (_: TouchEvent, direction: UseSwipeDirection) => {
    if (direction === 'left') {
      splide.value?.go('>')
    } else if (direction === 'right') {
      splide.value?.go('<')
    }
  },
})

const progress = ref(0)
const onAutoplayTick = (_: typeof Splide, rate: number) => {
  progress.value = rate * 100
}

const currentSlideIndex = ref(0)
const onSlideSwitch = (_: typeof Splide, newIndex: number) => {
  currentSlideIndex.value = newIndex
  progress.value = 0
}
</script>

<template>
  <Section v-animate="{ cluster: 'true' }">
    <Splide
      ref="splide"
      :options="options"
      :has-track="false"
      aria-label="highlights"
      @splide:autoplay:playing="onAutoplayTick"
      @splide:move="onSlideSwitch"
    >
      <SplideTrack class="splide-track">
        <SplideSlide v-for="(slide, index) in slides" :key="index" class="splide-slide">
          <Picture :small="slide.image.small" :large="slide.image.large" loading="eager" type="hero" />
        </SplideSlide>
      </SplideTrack>
    </Splide>
    <ul ref="swipeTarget" class="teasers cul" :style="{ ['--active-index']: `${currentSlideIndex}` }">
      <li v-for="(teaser, index) in slides" :key="index" v-animate="{ animation: 'vertical-reveal' }">
        <NuxtLink
          :class="['teaser', { active: index === currentSlideIndex }]"
          :to="teaser.link.url"
          :style="{ ['--d']: `${index === currentSlideIndex ? progress : 0}%` }"
          :target="teaser.link.target"
        >
          <div class="progress">
            <div class="bar" />
          </div>
          <span class="text">
            <span class="label">{{ teaser.label }}</span>
            <span :class="['title', index === currentSlideIndex ? 'f-21' : 'f-18', 'f-bold']">{{ teaser.title }}</span>
          </span>
          <Icon class="icon" name="link-arrow" />
        </NuxtLink>
      </li>
    </ul>
  </Section>
</template>
<style scoped lang="scss">
.section-hero-home {
  align-items: end;
  @include breakpoint(tl) {
    align-items: center;
  }
}
.splide {
  grid-row: 1;
  grid-column: 1 / -1;
  width: calc(50% + 50vw);
  @include breakpoint(tl) {
    --column-width: calc((100% - 11 * var(--gap)) / 12);
    --mr: calc(50% - min(50vw, calc(var(--layout-max-width) / 2)));
    grid-row: 1;
    grid-column: 4 / -1;

    width: auto;
    max-width: calc(100% + (-1 * var(--mr)) - 2 * var(--column-width) - 2 * var(--gap));
    margin-right: var(--mr);
  }
}
.teasers {
  grid-row: 1;
  grid-column: 1 / -1;

  padding-bottom: 18px;
  display: flex;
  gap: 8px;
  translate: 20px;

  width: calc(50vw + 50% - 20px);
  overflow: hidden;

  z-index: 1;
  @include breakpoint(tl) {
    grid-column: 1 / 6;
    grid-row: 1;

    padding-bottom: 60px;

    display: block;
    width: 100%;
    translate: 0;
  }

  @include breakpoint(ds) {
    padding-left: 90px;
  }

  li {
    min-width: min(400px, calc(min(100vw - 2 * var(--container-padding), var(--container-max-width))) - 40px);
    translate: calc(-100% * var(--active-index) - 8px * var(--active-index));
    @include transitions(translate);
    &:last-child a {
      border-bottom: none;
    }
    @include breakpoint(tl) {
      translate: none;
      min-width: 0;
    }
  }
}
.teaser {
  position: relative;

  display: flex;
  justify-content: space-between;
  padding: 30px 18px 36px 30px;
  background-color: var(--c-white);

  @include transitions(padding, box-shadow);

  &.active {
    z-index: 1;
  }
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(var(--c-primary), var(--c-primary)) left/var(--d) 100% no-repeat;
  }
  .text {
    display: flex;
    flex-direction: column;
    color: var(--c-primary);
    @include transitions(color);
    @include hover {
      color: var(--c-blue-rollover);
    }
  }

  .label {
    @include fluid(margin-bottom, 9px, 12px);
    @include transitions(opacity, margin-bottom);
  }
  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include f-21;
    @include transitions(font-size, line-height);
  }
  .icon {
    --size: 12px;
    width: var(--size);
    height: var(--size);
    flex-shrink: 0;
    align-self: center;

    display: none;

    translate: 30px;
    @include transitions(opacity, translate);
    transition-delay: 0.1s;

    @include breakpoint(tl) {
      display: block;
    }
  }

  @include breakpoint(tl) {
    padding: 48px 56px 65px;
    border-top: 1px solid transparent;
    box-shadow: var(--shadow-teaser);

    &:not(.active) {
      padding: 40px 92px 40px 56px;
      border-top-color: var(--c-line-light);
      box-shadow: var(--shadow-teaser-none);
      .label,
      .progress {
        opacity: 0;
      }

      &:not(:hover) {
        .text {
          color: var(--c-text-light);
        }
      }

      .label {
        margin-bottom: -15px;
      }
      .title {
        @include f-18;
      }

      &:not(:hover) {
        .text {
          color: var(--c-text-light);
        }
        .icon {
          opacity: 0;
          translate: 0;
        }
      }
    }
  }
}
.dp-picture {
  :deep(img) {
    width: 100%;
    @include fluid(height, 480px, 720px);
  }
}
</style>
